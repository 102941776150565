import React from 'react';
import { Image, Text, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';

import SEO from '../components/seo';
import { Layout } from '../layout';
import { Container } from '../components/container';
import { Header } from '../components/Header';

const PurchaseFailurePage = props => {
  const { t } = useTranslation();

  return (
    <Layout {...props}>
      <SEO title="PURCHASE FAILURE" keywords={['weboxit', 'boxit']} />
      <Header
        title={t('purchase.failurePage.title')}
        subtitle={t('purchase.failurePage.subtitle')}
      />
      <Container>
        <Flex flexDirection="column" alignItems="center">
          <Text
            pt="25px" 
            fontSize="35px" 
            lineHeight="0.74" 
            color="blues.peacock">
          { t('purchase.failurePage.text1') }
          </Text>
          <Text
            pt="25px"
            px={["0","120px","120px"]}
            fontSize="20px"
            color="marine"
            lineHeight="1.5"
            textAlign="center"
          >
          { t('purchase.failurePage.text2') }
          </Text>
          <Text
            pt="35px"
            fontSize="20px"
            color="marine"
            lineHeight="1.5"
            textAlign="center"
          >
          { t('purchase.failurePage.text3') }
          </Text>
        </Flex>
      </Container>
    </Layout>
  );
};

export default PurchaseFailurePage;
